.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Fix for mdi icons being too small next to normal buttons, we should expand with an actual button options component */
.button-large-mdi-icon {
  padding: 0.35rem 0.8rem;
}

.button-large-mdi-icon i {
  font-size: 1rem;
}

.row-chat-custom {
  height: 400px;
  max-height: 400px;
  overflow: scroll;
}

.table-responsive.table-overflow-y-hidden {
  overflow-y: hidden;
}

.action-icons-cell {
  text-align: center;
}

.action-icons-cell i {
  font-size: 20px;
}

.cursor-pointer-custom {
  cursor: pointer;
}

.box-value-one-four {
  font-size: 1.4rem;
}

.scrollbar-addition-dark::-webkit-scrollbar {
  width: 6px;
}

.scrollbar-addition-dark::-webkit-scrollbar-track {
  background: transparent;
}

.scrollbar-addition-dark.scrollbar-back-grey::-webkit-scrollbar-track {
  background: rgb(245,245,245);
}

.scrollbar-addition-dark::-webkit-scrollbar-thumb {
  background: rgba(170,170,170, 0.55);
  border-radius: 6px;
}

/* provide a single white background row inside of a thead-light thead */
table .thead-light .tr-white-important th {
  background-color: #FFF;
  text-align: center;
}

table .thead-light .tr-white-important th:first-child {
  text-align: left;
}

.authenticator-custom {
  margin-bottom: -60px;
}

/* This footer class override is only until the sidebar is enabled */
.content-page .footer {
  left: 0
}

.loading-button-spinner {
  margin-bottom: 2px;
}

/* Temporary min height until we have a custom css solution in place */

.loading-table-row {
  height: 500px;
}

/* Temporary until we have either an image or proper css for desired appearance */
.unread-light-icon {
  background-color: #2ebbdb;
  border-radius: 5px;
  content: ' ';
  height: 10px;
  left: -2px;
  position: absolute;
  top: calc(50% - 5px);
  width: 10px;
}

.unread-light-icon.unread-right {
  left: unset;
  right: -15px;
}

@media (max-width: 575px) {
  .order-xs-1 {
    order: 1;
  }
  .order-xs-2 {
    order: 2;
  }
}
